import $ from 'jquery';
import daterangepicker from 'daterangepicker'
import 'daterangepicker/daterangepicker.css'
import whatInput from 'what-input';
import Foundation from 'foundation-sites';
import MotionUI from 'motion-ui';
import jqueryUi from 'jquery-ui';
import webuiPopover from 'webui-popover';
import SumoSelect from 'sumoselect';
import TableSort from 'tablesorter';


import 'jquery-ui-dist/jquery-ui.css'
import 'jquery-ui-dist/jquery-ui.theme.css'
import 'jquery-ui-dist/jquery-ui.min';

import './scss/app.scss';


/*
| Import Fontawesome Pro packages
| * We serve and host these ourselves currently
| * .npmrc contains the Pro token
| *
|
*/
import {fontawesome} from '@fortawesome/fontawesome-pro';
import {library} from '@fortawesome/fontawesome-svg-core';

window.$ = $;
window.jQuery = $;

window.daterangepicker = daterangepicker;
window.whatInput = whatInput;
window.Foundation = Foundation;
window.MotionUI = MotionUI;
window.jqueryUi = jqueryUi;
window.webuiPopover = webuiPopover;
window.SumoSelect = SumoSelect;
window.tablesorter = TableSort;

// SOLID ICONS
import {
    faArrows as fasArrows,
    faArrowsH as fasArrowsH,
    faArrowsV as fasArrowsV,
    faArrowToBottom as fasArrowToBottom,
    faBars as fasBars,
    faCalendarAlt as fasCalendarAlt,
    faCar as fasCar,
    faCarBuilding as fasCarBuilding,
    faCheck as fasCheck,
    faCheckCircle as fasCheckCircle,
    faChevronCircleLeft as fasChevronCircleLeft,
    faChevronCircleRight as fasChevronCircleRight,
    faCircle as fasCircle,
	faCircleXmark as fasCircleXmark,
    faEllipsisH as fasEllipsisH,
    faEnvelope as fasEnvelope,
    faExclamationCircle as fasExclamationCircle,
    faExclamationTriangle as fasExclamationTriangle,
    faExternalLinkSquare as fasExternalLinkSquare,
    faEye as fasEye,
    faEyeSlash as fasEyeSlash,
    faFileAlt as fasFileAlt,
    faFileArchive as fasFileArchive,
    faFileImage as fasFileImage,
    faFilePdf as fasFilePdf,
    faFolder as fasFolder,
    faHomeAlt as fasHomeAlt,
    faLock as fasLock,
    faMinusCircle as fasMinusCircle,
    faNotEqual as fasNotEqual,
    faPenSquare as fasPenSquare,
    faPlusCircle as fasPlusCircle,
    faQuestionCircle as fasQuestionCircle,
    faRedo as fasRedo,
    faSearch as fasSearch,
    faShieldCheck as fasShieldCheck,
    faSlidersH as fasSlidersH,
    faSync as fasSync,
    faTimes as fasTimes,
    faTrashAlt as fasTrashAlt,
    faUndo as fasUndo,
    faUnlock as fasUnlock,
    faUser as fasUser,
    faUserMinus as fasUserMinus,
    faUserPlus as fasUserPlus
} from '@fortawesome/pro-solid-svg-icons';

// Add the Solid icons
library.add(
    fasTrashAlt,
    fasFilePdf,
    fasFileArchive,
    fasFileAlt,
    fasFileImage,
    fasSearch,
    fasEnvelope,
    fasPenSquare,
    fasUser,
    fasUserPlus,
    fasUserMinus,
    fasCircle,
	fasCircleXmark,
    fasQuestionCircle,
    fasMinusCircle,
    fasPlusCircle,
    fasExternalLinkSquare,
    fasExclamationTriangle,
    fasArrowToBottom,
    fasChevronCircleRight,
    fasChevronCircleLeft,
    fasSlidersH,
    fasLock,
    fasUnlock,
    fasEye,
    fasEyeSlash,
    fasArrows,
    fasArrowsH,
    fasArrowsV,
    fasBars,
    fasCalendarAlt,
    fasSync,
    fasUndo,
    fasRedo,
    fasCar,
    fasCarBuilding,
    fasCheck,
    fasTimes,
    fasCalendarAlt,
    fasFolder,
    fasCheckCircle,
    fasExclamationCircle,
    fasEllipsisH,
    fasNotEqual,
    fasShieldCheck,
    fasHomeAlt
);

// BRANDS
import {
	faTwitter as fabTwitter
} from '@fortawesome/free-brands-svg-icons';

library.add(
	fabTwitter
);
	
// Import Regular Icons
import {
	faEllipsisV as farEllipsisV,
	faSpinnerThird as farSpinnerThird
} from '@fortawesome/pro-regular-svg-icons';

// Add Regular Icons
library.add(
	farEllipsisV,
    farSpinnerThird
);

// LIGHT ICONS
import {
    faArrows as falArrows,
    faArrowsH as falArrowsH,
    faArrowsV as falArrowsV,
    faArrowToBottom as falArrowToBottom,
    faBars as falBars,
    faCalendarAlt as falCalendarAlt,
    faCar as falCar,
    faCheck as falCheck,
    faCheckCircle as falCheckCircle,
    faChevronCircleLeft as falChevronCircleLeft,
    faChevronCircleRight as falChevronCircleRight,
    faCircle as falCircle,
	faCircleXmark as falCircleXmark,
    faEllipsisH as falEllipsisH,
    faEllipsisV as falEllipsisV,
    faEnvelope as falEnvelope,
    faExclamationCircle as falExclamationCircle,
    faExclamationTriangle as falExclamationTriangle,
    faExternalLinkSquare as falExternalLinkSquare,
    faEye as falEye,
    faEyeSlash as falEyeSlash,
    faFileAlt as falFileAlt,
    faFileArchive as falFileArchive,
    faFileDownload as falFileDownload,
    faFileImage as falFileImage,
    faFilePdf as falFilePdf,
	faFileCsv as falFileCsv,
    faFilter as falFilter,
    faFolder as falFolder,
    faLock as falLock,
    faMinusCircle as falMinusCircle,
    faNotEqual as falNotEqual,
    faPenSquare as falPenSquare,
    faPlusCircle as falPlusCircle,
    faQuestionCircle as falQuestionCircle,
    faRedo as falRedo,
    faSearch as falSearch,
    faShieldCheck as falShieldCheck,
    faSlidersH as falSlidersH,
    faSync as falSync,
    faTimes as falTimes,
    faTrashAlt as falTrashAlt,
    faUndo as falUndo,
    faUnlock as falUnlock,
    faUserMinus as falUserMinus,
    faUserPlus as falUserPlus
} from '@fortawesome/pro-light-svg-icons';

// Add the Light icons
library.add(
    falTrashAlt,
    falFilePdf,
    falFileCsv,
    falFileArchive,
    falFileDownload,
    falFileAlt,
    falFileImage,
    falFilter,
    falSearch,
    falEnvelope,
    falPenSquare,
    falUserPlus,
    falUserMinus,
    falCircle,
	falCircleXmark,
    falQuestionCircle,
    falMinusCircle,
    falPlusCircle,
    falExternalLinkSquare,
    falExclamationTriangle,
    falArrowToBottom,
    falChevronCircleRight,
    falChevronCircleLeft,
    falSlidersH,
    falLock,
    falUnlock,
    falEye,
    falEyeSlash,
    falArrows,
    falArrowsH,
    falArrowsV,
    falBars,
    falCalendarAlt,
    falSync,
    falUndo,
    falRedo,
    falCheck,
    falTimes,
    falCalendarAlt,
    falFolder,
    falCar,
    falCheckCircle,
    falExclamationCircle,
    falEllipsisH,
    falEllipsisV,
    falNotEqual,
    falShieldCheck
);

// Final setup...
window.fontawesome = fontawesome;